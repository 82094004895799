import React, { useState, useEffect, useRef } from 'react';
import '../styles/Venue.css';
import initials from '../images/initials.png';
import wreath from '../images/wreath.png';
import { Fade } from 'react-reveal';
import Map from './Map';
import Maptwo from './Maptwo';
import Smallernav from './Smallernav';

const Venue = () => {
  const [showRSVP, setShowRSVP] = useState(false);
  const backgroundRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const background = backgroundRef.current;

      if (background) {
        const backgroundOffset = background.offsetTop;
        const backgroundHeight = background.offsetHeight;
        const backgroundScrollPos = backgroundOffset - scrollPosition;
        const backgroundPercentage = Math.round((backgroundScrollPos / backgroundHeight) * 100);

        background.style.backgroundPositionY = `${50 + backgroundPercentage * 0.1}%`;

        if (scrollPosition > windowHeight - 5) {
          setShowRSVP(true);
        } else {
          setShowRSVP(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleRSVP = () => {
    window.location.href = '/rsvp';
  };


  return (
    <div className="venue-container">
            <div className='share-headert'>
                <Smallernav />
            </div>
      {/* First Section */}

      {/* Second Section */}
      <div className="schedule-section">
        <div className="schedule-info">
        <Fade right duration={3000}>
          <h2>Schedule</h2>
          <p>We're getting married! We'd like to share our priceless moment with you!</p>
          </Fade>
        </div>
      </div><hr />
      <div ref={backgroundRef} className="background-imageder">
      <Fade top duration={3000}>
        <div className='mobile-logo' style={{ marginTop: '2.1rem' }}>
          <img src={wreath} className='mobile-logo-wreath'  alt="wreath" />
          <img src={initials} className='mobile-logo-initials'  alt="initials" />
            </div>
        </Fade>
      </div>


      {/* Third Section */}
      <div className="details-section">
        <div className="left-mini-div">
        <Fade left duration={3000}>
        <h3>Thursday</h3>
        </Fade>
        <Fade right duration={3000}>
          <p>MAY 2, 2024</p>
          <p>2PM</p>
        </Fade>
        </div>
        <div className="right-mini-div">
        <Fade top duration={3000}>
          <h1>Traditional wedding</h1>
          <p>Gbawe, close to faith montessori school</p>
          <p>HMWR+74J Gbawe</p>
          </Fade>
          <Fade bottom duration={3000}>
          <Map />
          </Fade>
        </div>
      </div><br />


      <div className="details-section">
        <div className="left-mini-div">
        <Fade bottom duration={3000}>
        <h3>Saturday</h3>
          <p>MAY 4, 2024</p>
          <p>2PM</p>
          </Fade>
        </div>
 
        <div className="right-mini-div">
          <h1>White wedding</h1>
          <p>Mt. Zion Methodist Church, Sakumono</p>
          <p>P23P+RH Tema</p>
          <Fade bottom duration={3000}>
          <Maptwo />
          </Fade>
        </div>
      </div>


            {/* Fourth Section (similar to Third Section)
            <div className="details-section">
        <div className="left-mini-div">
          <button>RSVP</button>
        </div>
        <div className="right-mini-div">
          <h2 className="centered-text">Reception</h2>
          <p>Paragraph 1</p>
          <p>Paragraph 2</p>
        </div>
      </div> */}

      {/* RSVP Prompt */}
      {showRSVP && (
        <Fade top duration={3000}>
        <div className="rsvp-prompt">
          Join us!
          <button className='' onClick={handleRSVP}>R.S.V.P</button>
        </div>
        </Fade>
      )}
    </div>
  );
};

export default Venue;
