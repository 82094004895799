import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Import the arrow icon
import Tooltip from '@material-ui/core/Tooltip';
import '../styles/CopyInfo.css';
import { useState } from 'react';

const CopyInfo = ({ handledonate, backtosharepage }) => {
  const phoneNumber1 = '0232471888';
  const phoneNumber2 = '0209060215';
  const accountnumber = '9040011669209';

  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copied3, setCopied3] = useState(false);

  const [hideforsec1, setHideForSec1] = useState(true);
  const [hideforsec2, setHideForSec2] = useState(true);
  const [hideforsec3, setHideForSec3] = useState(true);

  const copyToClipboard = (phoneNumber, index) => {
    navigator.clipboard.writeText(phoneNumber);
    setHideForSec(index, false);
    setTimeout(() => {
      setHideForSec(index, true);
    }, 2000);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const setCopied = (index, value) => {
    switch (index) {
      case 1:
        setCopied1(value);
        break;
      case 2:
        setCopied2(value);
        break;
      case 3:
        setCopied3(value);
        break;
      default:
        break;
    }
  };

  const setHideForSec = (index, value) => {
    switch (index) {
      case 1:
        setHideForSec1(value);
        break;
      case 2:
        setHideForSec2(value);
        break;
      case 3:
        setHideForSec3(value);
        break;
      default:
        break;
    }
  };


  return (
    <div className="phone-numbers-container">
   <div className="back-arrow" onClick={backtosharepage} style={{ margin: '0 auto', marginBottom: '2rem' }}>

        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <h3>Click to copy registry information below, if mode of payment is Mobile money click 'continue'</h3>
      {hideforsec1 && (
        <div className="phone-number">
          <span>{phoneNumber1}</span>
          <Tooltip title="Copy phone number">
            <FontAwesomeIcon
              icon={faCopy}
              className="copy-icon"
              onClick={() => copyToClipboard(phoneNumber1, 1)}
            />
          </Tooltip>
          <label>Joseph Ablade</label>
          {copied1 && <p>Copied</p>}
        </div>
      )}
        
        
      {hideforsec2 && (
        <div className="phone-number">
            
          <span>{phoneNumber2}</span>
          <Tooltip title="Copy phone number">
            <FontAwesomeIcon
              icon={faCopy}
              className="copy-icon"
              onClick={() => copyToClipboard(phoneNumber2, 2)}
            />
          </Tooltip>
          <label>Geraldine Sappor</label>
          {copied2 && <p>Copied</p>}
        </div>
      )}
<label>Joseph Nii Nuertey Ablade /Geraldine Atta Sappor - Junction mall, Stanbic bank</label>
      {hideforsec3 && (
        <div className="phone-number">
          <span>{accountnumber}</span>
          <Tooltip title="Copy account details">
            <FontAwesomeIcon
              icon={faCopy}
              className="copy-icon"
              onClick={() => copyToClipboard(accountnumber, 3)}
            />
          </Tooltip>
          {copied3 && <p>Copied</p>}
        </div>
      )}
      <button className="randombutton-design" onClick={handledonate}>
        Continue
      </button>
    </div>
  );
};

export default CopyInfo;
