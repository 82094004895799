import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import '../styles/Moments.css';
import Smallernav from './Smallernav';

const SharedGallery = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state for initial fetch

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const response = await axios.get('https://test-wedding-backend.vercel.app/imageUrls');
        setImageUrls(response.data); // Assuming the response.data is an array of image URLs
        setLoading(false); // Update loading state after fetching
      } catch (error) {
        console.error('Error fetching image URLs:', error);
        setLoading(false); // Update loading state after error
      }
    };

    fetchImageUrls();

    // Create WebSocket connection
    const socket = new WebSocket('ws://localhost:8080');

    socket.onmessage = function(event) {
      console.log('New photo uploaded');
      fetchImageUrls(); // Fetch new image URLs on WebSocket message
    };

    socket.onerror = function(event) {
      console.error('WebSocket error:', event);
      setLoading(false); // Update loading state after WebSocket error
    };

    return () => {
      socket.close();
    };
  }, []);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const navigate = (direction) => {
    if (direction === 'prev' && selectedImageIndex > 0) {
      setSelectedImageIndex((prevIndex) => prevIndex - 1);
    } else if (direction === 'next' && selectedImageIndex < imageUrls.length - 1) {
      setSelectedImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className="photo-gallery">
      <div className='share-headert'>
        <Smallernav />
      </div>

      {loading ? (
        <p className="loading-message">Loading...</p>
      ) : (
        imageUrls.map((imageUrl, index) => (
          <div key={index} className="gallery-item" onClick={() => openModal(index)}>
            <img src={imageUrl} alt={`Img ${index}`} loading='lazy' />
          </div>
        ))
      )}

      {imageUrls.length === 0 && !loading && (
        <p className="no-memories">No memories yet</p>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modalt"
        overlayClassName="overlay"
      >
        <button className="modal-close-btn" onClick={closeModal}>Close</button>
        <button className="nav-btn-prev" onClick={() => navigate('prev')}>{'<'}</button>
        <div className="modal-content">
          <img src={imageUrls[selectedImageIndex]} alt={`Img ${selectedImageIndex + 1}`} />
        </div>
        <button className="nav-btn-next" onClick={() => navigate('next')}>{'>'}</button>
      </Modal>
    </div>
  );
};

export default SharedGallery;
