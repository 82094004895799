import React, { useEffect, useState, useRef } from 'react';
import Main from '../Pages/Main';
import Mobile from '../Pages/Mobile';


function Homepage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  useEffect(() => {

    setTimeout(() => {
        setShowModal(true);
      }, 8000);
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };


    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return (
      <div className='spinnergffff'>
      <div class="loader">#theAblades
        <span></span>
      </div>
      </div>
    );
  }


  return (
    <div>
      {windowWidth <= 912 ? <Mobile /> : null}
      {windowWidth > 912 ? <Mobile /> : null}
      {showModal && (
        <div className="modal-container">
          <div ref={modalRef} className="modal">
          <button onClick={closeModal}>x</button><br />
            <p>
            We're getting married this May! 🎉 We'd love for you to celebrate with us. All the important info (dates, travel, etc.) is on this wedding website. Please RSVP by 26th April 2024 by clicking below .
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Homepage;
