import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Share from './Pages/Share';
import Rsvp from './Pages/Rsvp';
import './App.css';
import Moments from './Pages/Moments';
import Mobile from './Pages/Mobile';
import Homepage from './Pages/Homepage';
import Venue from './Pages/Venue';
import Donations from './Pages/Donations';
import SharedGallery from './Pages/SharedGallery';

const App = () => {

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/share" element={<Share />} />
          <Route path="/rsvp" element={<Rsvp />} />
          <Route path="/venue" element={<Venue />} />
          <Route path="/moments" element={<Moments />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/wedding-registry" element={<Donations />} />
          <Route path="/live-moments" element={<SharedGallery />} />
          
        </Routes>
      </div>
    </Router>
  );
};

export default App;
