import React, { useState } from 'react';
import CameraIcon from '../images/icons8-camera-100.png';
import GalleryIcon from '../images/icons8-gallery-80.png';
import '../styles/Share.css';
import axios from 'axios';
import initials from '../images/initials.png';
import wreath from '../images/wreath.png';
import { Fade } from 'react-reveal';
import Smallernav from './Smallernav';

const Share = () => {
    const [showOptions, setShowOptions] = useState(false);
    const [images, setImages] = useState([]);
    const [hidesharebutton, setHideShareButton] = useState(true);
    const [success, setSuccess] = useState(false);
    const [sizeLimit, setSizeLimit] = useState(false);
    const [exceededLimit, setExceededLimit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const validImages = files.filter(file => {
            if (file.size > 10 * 1024 * 1024) {
                setSizeLimit(true);
                setTimeout(() => {
                    setSizeLimit(false);
                }, 3000);
                return false;
            }
            return file.type.startsWith('image/');
        });
        
        if (validImages.length + images.length > 4) {
            setExceededLimit(true);
            setTimeout(() => {
                setExceededLimit(false);
            }, 3000);
            return;
        }
        
        setImages([...images, ...validImages]);
    };

    const handleDeleteImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const handleUpload = async () => {
        if (images.length === 0) {
            alert('Please select at least one image.');
            return;
        }

        setLoading(true);

        setTimeout(() => {
            setImages([]);
        }, 1400);

        setTimeout(() => {
            setLoading(false);
            setSuccess(true);
            
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        }, 3000);


    
        try {
            // Cloudinary upload URL
            let cloudname = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
            let api_key = process.env.REACT_APP_CLOUDINARY_API_KEY;
            let api_secret = process.env.REACT_APP_CLOUDINARY_API_SECRET;
            let upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
            let resourcetype = 'image';
            let folderName = 'josephxgericloudphotos';
            let api = `https://api.cloudinary.com/v1_1/${cloudname}/${resourcetype}/upload?api_key=${api_key}&api_secret=${api_secret}&upload_preset=${upload_preset}`;
            
            const imageUrls = [];
            for (const image of images) {
                const formData = new FormData();
                formData.append('file', image);
                formData.append('folder', folderName);
                
                const response = await axios.post(api, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                const imageUrl = response.data.secure_url;
                imageUrls.push(imageUrl);
            }
    
            console.log('Image URLs:', imageUrls);
    
            // Send image URLs to the server
            const formDataToServer = {
                images: imageUrls,
            };
    
            const serverResponse = await axios.post('https://test-wedding-backend.vercel.app/upload', formDataToServer);
            // const serverResponse = await axios.post('https://relieved-capris-mite.cyclic.app/upload', formDataToServer);
            console.log('Server Response:', serverResponse);
    
        } catch (error) {
            console.error('Error uploading images:', error);
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };
    
    
    
    
    const handleShare = () => {
        setHideShareButton(false);
        setShowOptions(true);
    };

    return (
        <div className='share-space'>
            <div className='share-headert'>
                <Smallernav />
            </div>


            {success && (
                <Fade top duration={3000}>
                    <p>Uploaded!</p>
                </Fade>
            )}

            <Fade top  duration={3000}>
                <div className='mobile-logo' style={{ marginTop: '2.1rem' }}>
                    <img src={wreath} className='mobile-logo-wreath'  alt="wreath" />
                    <img src={initials} className='mobile-logo-initials'  alt="initials" />
                </div>
            </Fade>

            {hidesharebutton && (
                <div className='share-texts'>
                    <h1>Share your moments</h1>
                    <p>Share your moments with us by uploading pictures from your gallery or taking a picture with your camera.</p>
                    <button className='rsvp-buttons'  onClick={handleShare}>Share</button>
                </div>
            )}

            {showOptions && (
                <div className='upload-icons' style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="cameraInput">
                        <img src={CameraIcon} alt="camera icon" style={{ width: '50px', cursor: 'pointer' }} />
                        <br />
                        Camera
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        capture="camera"
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        id="cameraInput"
                    />
                    <label htmlFor="galleryInput">
                        <img src={GalleryIcon} alt="gallery icon" style={{ width: '50px', cursor: 'pointer' }} />
                        <br />
                        Gallery
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        id="galleryInput"
                    />
                </div>
            )}

            {loading && (
                <div id="col">
                <div id="img-wrap">
                    <span class="loadersmall"></span>
                </div></div>
            )}


            <div className="image-preview">
                {images.slice(0, 4).map((image, index) => (
                    <div key={index} className="preview-item">
                        <img src={URL.createObjectURL(image)} alt={`Img ${index}`} />
                        <button className='cancel-button' onClick={() => handleDeleteImage(index)}>X</button>
                    </div>
                ))}
                
            </div><br />
            {images.length > 0 && (
                <button className='rsvp-buttons' onClick={handleUpload}>Upload Images</button>
            )}
            {exceededLimit && (
                <div className='pmt-box'>
                <p className='pmt'>You can only upload up to <strong>4</strong> images at a time.</p>
                </div>
            )}
            {sizeLimit && (
                <div className='pmt-box'>
                <p className='pmt'>Image size should not exceed <strong>10MB</strong>.</p>
                </div>
            )}
        </div>
    );
};

export default Share;
