import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';
import initials from '../images/initials.png';
import wreath from '../images/wreath.png';
import { Fade } from 'react-reveal';
import Smallernav from './Smallernav';

const Dashboard = () => {
  const [names, setNames] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('https://test-wedding-backend.vercel.app/dashboard/');
        setNames(response.data);
      } catch (error) {
        console.error('Error during dashboard retrieval:', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="dashboard-container">
            <div className='share-headert'>
                <Smallernav />
            </div>
        <Fade top  duration={3000}>
        <div className='logo'>
          <img src={wreath} className='logo-wreath'  alt="wreath" />
          <img src={initials} className='logo-initials'  alt="initials" />
            </div>
        </Fade>
      <h2 className="dashboard-title">R.S.V.P list</h2>
      <hr />
      <p>{names.length} Guests</p>
      
      {names.length === 0 ? (
        <p className="no-names-message">No names listed yet</p>
      ) : (
        <ul className="names-list">
          {names.map((name, index) => (
            <li key={name} className="names-list-item">
             <strong>{index +1}. </strong> {name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dashboard;
