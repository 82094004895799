// Donations.js
import React, { useState } from 'react';
import '../styles/Share.css';
import initials from '../images/initials.png';
import wreath from '../images/wreath.png';
import { Fade } from 'react-reveal';
import CopyInfo from './CopyInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Import the back arrow icon
import Smallernav from './Smallernav';

const Donations = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [hidesharebutton, setHideShareButton] = useState(true);
  const [showcopy, setShowCopy] = useState(false);

  const openmtnphone = () => {
    window.open('tel:*170#');
  };

  const openairtelphone = () => {
    window.open('tel:*110*1#');
  };

  const openvodafonephone = () => {
    window.open('tel:*110#');
  };


  const handlecopy = () => {
    setHideShareButton(false);
    setShowCopy(true);
  };

  const handledonate = () => {
    setShowCopy(false);
    setShowOptions(true);
  };

  const handleBack = () => {
    setShowOptions(false);
    setShowCopy(true);

  };

  const backtosharepage = () => {
    setHideShareButton(true);
    setShowCopy(false);
    }

  return (
    <div className="share-space">
          <div className='share-headert'>
                <Smallernav />
            </div>
      <Fade top duration={3000}>
        <div className="mobile-logo" style={{ marginTop: '2.1rem' }}>
          <img src={wreath} className="mobile-logo-wreath" alt="wreath" />
          <img src={initials} className="mobile-logo-initials" alt="initials" />
        </div>
      </Fade>

      {showOptions && (
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="back-arrow"
          onClick={handleBack}
        />
      )}


      {hidesharebutton && (
        <>
        <div className='donation-message'>
          <h1>Wedding Registry</h1>
        <p>Your presence on this special day means a lot to us. However, if you wish to bless us with a gift or generously contribute to the start of our beautiful journey as a married couple, we ask that you kindly click the link below for details</p>
        </div>
          <button className="rsvp-buttons" onClick={handlecopy}>
            Browse Registry
          </button>
        </>
      )}

      {showcopy && (
        <div className="upload-icons" style={{ marginTop: '10px', display: 'flex' }}>
          <div className="momo">
            <CopyInfo handledonate={handledonate} backtosharepage={backtosharepage} />
          </div>
        </div>
      )}

      {showOptions && (
        <div className="upload-icons" style={{ marginTop: '15px', display: 'flex' }}>
          <div className="momo">
            <div className="telco1" onClick={openmtnphone}>
              <p>MTN</p>
            </div>
            <div className="telco2" onClick={openairtelphone}>
              <p>Airtel Tigo</p>
            </div>
            <div className="telco3" onClick={openvodafonephone}>
              <p>Vodafone</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Donations;
