import React, { useState } from 'react';
import '../styles/Vendors.css'; // Import CSS for styling

const Vendors = () => {
    const [vendorAppear, setVendorAppear] = useState(false);
    const [buttondisappear, setButtondisappear] = useState(true);

    const brands = {
        "Decor": "event_doriginelle",
        "Makeup": "anaviosi__",
        "Hair": "posh hair_ gh",
        "Couples kente": "sesea kente",
        "Brides kente gown": "sabali.clodin",
        "Brides white gown": "sabali.clodin",

        "Groom suit": "urbangreygh",
        "Groom’s kaftan": "grandpa_clothing",

        "Groom’s traditional accessories": "bb_dazzle_me",
        "Photography": "nabimagery",
        "Videography": "wycfilms_",

        "Engagement food": "anns.kitchencateringservices",
        "Cake": "ovenly_gh",
        "Dj": "Djemay Sixnine",

        "Cocktail bar": "cautioncocktailbar",
        "Mc": "_aakolly",
        "Wedding Website": "we_devsgh",
        "Event Setup & Designs": "clone inventive",
        "Grooms Ahenema" : "jjprofessionalshoesgh",
        "360 booth": "specxphotography",
        "Grooms Suit accessories": "Opulence_ties",

    };

    
const urlvalue = [
    "event_doriginelle?igsh=eWxtamV2N2dndDVu",
    "anaviosi__?igsh=MWdzNG5qdmRxM2p6eA==",
    "posh_hair_gh?igsh=aG5remg0Y3p2c3Bp",
    "sesea_kente?igsh=anJtanJxMzU3aGp3",
    "sabali.clodin?igsh=OW9zdXpxbWx3eGky",
    "sabali.clodin?igsh=OW9zdXpxbWx3eGky",
    "urbangreygh?igsh=MWZtYmZicmhibTAwbg==",
    "grandpa_clothing?igsh=MTVyZGRyN3hrcDRqcQ==",
    "bb_dazzle_me?igsh=eDlhcWl0a3NvajB5",
    "nabimagery?igsh=MWxqdTFqZTI1YXR6eQ==",
    "wycfilms_?igsh=OG9qYXhiMWFqcHJ2",
    "anns.kitchencateringservices?igsh=Z2JiZmNxaG5udjhz",
    "ovenly_gh?igsh=NG1nbjFzcjJnczRq",
    "dj_e_m_a_y69?igsh=MTBveGJkdHZ4NjlkZA==",
    "cautioncocktailbar?igsh=a3hvY2F5d25nNWY2",
    "_aakolly?igsh=MWpheHBwdWNwMnk0Ng==",
    "we_devsgh?igsh=azhkb2Nzcjh3aHNp",
    "clone_inventive?igsh=dHEyNnl0YXBoZmJp",
    "jjprofessionalshoesgh?igsh=YXEzM2ExNjF5MGo2",
    "specxphotography?igsh=MWc1bDQzMmV4bWpvdA==",
    "opulence_ties?igsh=MTNnemZ2eG16aTZmMw=="

];

    const handleVendorAppear = () => {
        setVendorAppear(!vendorAppear); // Toggle the state
        setButtondisappear(false); // Hide the button
    }

    return (
        <div className="brand-tray">
            <div className="brand-tray-container">

                <div className='vendor-reveal-section'>
                {buttondisappear && (
                <button className="bn" onClick={handleVendorAppear}>
                    Vendors
                </button>
                )}
                </div>
                {vendorAppear && (
                    <div className="brand-list fade-down">
                        {Object.entries(brands).map(([key, value], index) => (
                            <div className='brand-box' key={key}>
                                <button className="brand-list-item">
                                <a href={`https://www.instagram.com/${urlvalue[index]}`} target="_blank" rel="noreferrer">{value}</a>
                                </button>
                                <br />
                                <p>{key}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Vendors;
