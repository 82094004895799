import React from 'react';
import '../styles/Mobile.css';
import NavBar from './NavBar';
import initials from '../images/initials.png';
import wreath from '../images/wreath.png';
import and from '../images/bigand.png';
import joseph from '../images/slimjoseph.png';
import gealdine from '../images/slimgeri.png';
import { Fade } from 'react-reveal';
import Vendors from '../components/Vendors';

const Mobile = () => {


  const handleRSVP = () => {
    window.location.href = '/rsvp';
  };

  return (
    <div className="mobile-container">
      <NavBar />
      {/* Home section */}
      <section className="home-section">
        <Fade top  duration={3000}>
        <div className='mobile-logo'>
          <img src={wreath} className='mobile-logo-wreath'  alt="wreath"  />
          <img src={initials} className='mobile-logo-initials'  alt="initials" />
          </div>
        </Fade>

        
          {/* Text content */}
          {/* <div className="text-content">
          <Fade left  duration={3000}>
        <h1 id='top-text'>Joseph</h1>
        </Fade>
        <img src={and} className='and-img' alt="and" /> 
        <Fade right  duration={3000}>
        <h1 id='lower-text'>Geraldine</h1>
        </Fade>
          </div> */}

          <div className="text-content">
          <Fade left duration={3000}>
          <img src={joseph} className='and-img' alt="josephimg" /> 
        </Fade>
        <img src={and} className='and-img' alt="and" /> 
        <Fade right  duration={3000}>
        <img src={gealdine} className='and-img' alt="geraldineimg" /> 
        </Fade>
          </div>


        {/* Background image */}
        <div className="background-images">
          <button className='button' onClick={handleRSVP}>R.S.V.P</button>
        </div>

        {/* Countdown component */}

      </section>
        <Vendors />
    </div>
  );
};


export default Mobile;
