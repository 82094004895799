import React, { useState } from 'react';
import Modal from 'react-modal';
import '../styles/Moments.css';
import Smallernav from './Smallernav';
import img1 from '../images/collage/one.jpg';
// import img2 from '../images/collage/two.jpg';
// import img3 from '../images/collage/three.jpg';
// import img4 from '../images/collage/four.jpg';
// import img5 from '../images/collage/five.jpg';
// import img6 from '../images/collage/six.jpg';
import img7 from '../images/collage/seven.jpg';
// import img8 from '../images/collage/eight.jpg';
import img9 from '../images/collage/nine.jpg';
import img10 from '../images/collage/ten.jpg';
import img11 from '../images/collage/eleven.jpg';
import img12 from '../images/collage/twelve.jpg';
// import img13 from '../images/collage/thirteen.jpg';
// import img14 from '../images/collage/forteeen.jpg';
// import img15 from '../images/collage/fifteen.jpg';
import img16 from '../images/collage/sixteen.jpg';
// import img17 from '../images/collage/seventeen.jpg';
import img18 from '../images/collage/eighteen.jpg';
import img19 from '../images/collage/nineteen.jpg';
// import img20 from '../images/collage/twenty.jpg';
import img21 from '../images/collage/twenty-one.jpg';
import { Fade } from 'react-reveal';

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const images = [img1, img7, img9, img10, img11, img12, img16, img18, img19, img21];

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const navigate = (direction) => {
    if (direction === 'prev' && selectedImageIndex > 0) {
      setSelectedImageIndex((prevIndex) => prevIndex - 1);
    } else if (direction === 'next' && selectedImageIndex < images.length - 1) {
      setSelectedImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className="photo-gallery">
             <div className='share-headert'>
                <Smallernav />
            </div>
      {images.map((image, index) => (
        <div key={index} className="gallery-item" onClick={() => openModal(index)}>
          <Fade top duration={3000}>
          <img src={image} alt={`Img ${index + 1}`} loading='lazy' />
          </Fade>
        </div>
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modalt"
        overlayClassName="overlay"
      >
        <button className="nav-btn-prev" onClick={() => navigate('prev')}>{'<'}</button>
        <div className="modal-content">

          <img src={images[selectedImageIndex]} alt={`Img ${selectedImageIndex + 1}`} />
         
        </div>
        <button className="nav-btn-next" onClick={() => navigate('next')}>{'>'}</button>
      </Modal>
    </div>
  );
};

export default Gallery;
