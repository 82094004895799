import React from 'react';
import '../styles/Map.css';
import churchimage from '../images/collage/Mountzion.jpg';


function Map() {
  const handleImageClick = () => {
    // Redirect user to Google Maps link
    window.location.href = 'https://maps.app.goo.gl/RVXCxAFz9cxs3YPS9';
  };

  return (
    <div className="map-container" id='map-details'>
      <div className="map-image" onClick={handleImageClick} style={{ backgroundImage: `url(${churchimage})` }}>
        <div className="map-overlay">
          <h3>Click for location <i class="fa fa-map-pin" aria-hidden="true"></i></h3>
        </div>
      </div>
    </div>
  );
}

export default Map;
