import React, { useState } from 'react';
import '../styles/Navbar.css';
import { Link } from 'react-router-dom';
import initials from '../images/initials.png';
import wreath from '../images/wreath.png';

const NavBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };


  const handleRSVP = () => {
    window.location.href = '/rsvp';
  };

  // const scrollToSection = (id) => {
  //   const element = document.getElementById(id);
  //   element.scrollIntoView({ behavior: 'smooth' });
  // };

  // const scrollToSections = (id) => {
  //   const element = document.getElementById(id);
  //   element.scrollIntoView({ behavior: 'smooth' });
  //   toggle();
  // };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="nav-it" onClick={toggle}>
        <div className="navbar-hamburger" onClick={toggle}>
          <div className="lines"></div>
          <div className="lines"></div>
          <div className="lines"></div>
        </div>
        </div>


        {isModalOpen && (
          <div className="modal-overlay" onClick={toggle}>
            <div className="modals" onClick={(e) => e.stopPropagation()}>
            <div className='logo'>
              <img src={wreath} className='logo-wreath'  alt="wreath" />
              <img src={initials} className='logo-initials'  alt="initials" />
              </div>
                <Link className="sidelinks" to="/">Home</Link>
                <Link className="sidelinks" to="/rsvp">R.S.V.P</Link> 
                <Link className="sidelinks" to="/moments">Moments</Link>
                <Link className="sidelinks" to="/venue">Event Details</Link>
                <Link className="sidelinks" to="/wedding-registry">Wedding Registry</Link> 
                <Link className="sidelinks" to="/share">Share your moments</Link>
                <Link className="sidelinks" to="/live-moments">live moments</Link>
                
                <div className='sidbtn'>
                <button className='button' onClick={handleRSVP}>RSVP</button>
                </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
